body {
  background-color: black;
  color: white;
  font-weight: bolder;
}

.App {
  display: flex;
  height: fit-content;
}


.BingoCard {
  margin: 0 auto;
  padding: 0;
}

.ShoutBingo {
  margin: 1rem auto;
  background-size: 100% 100%;
  min-height: 6.5rem;
  max-width: 30rem;
}

.BingoCardHeader {
  margin: 1rem auto;
  background-size: 100% 100%;
  min-height: 6.5rem;
}

.BingoCard table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  max-width: 30rem;
  min-height: 20rem;
}

.SpotifyLink {
  padding: 2px 8px;
  color: greenyellow;
}

tr {
  max-width: 30rem;
  display: flex;
  width: 100vw;
}

.BingoCardGridEntry {
  font-size: .6rem;
  flex: 1;
  border: solid 2px white;
}

.winning {
  background-color: red;
}

.BingoCardGridEntryDot {
  display: flex;
  border-radius: 50%;
  text-align: center;
  width: 100%;
  min-height: 4rem;
  flex-direction: column;
  justify-content: center;
}

.crossed-out {
  background-color: purple;
}

@media(max-width: 576px) {
  .BingoCardGridEntry {
    font-size: 7px;
  }
}

